import * as React from "react";
import 'bootstrap/dist/css/bootstrap.css'
import Logo from '../images/lifeguide-logo.png';
import FooterLogo from '../images/logo-footer.png';
import "@fontsource/inter"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "../styles/main.scss"
import background from '../images/dashboard-images2.png'
import bubbleLogo1 from '../images/bubble-icon-1.png'
import bubbleLogo2 from '../images/bubble-icon-2.png'
import bubbleLogo3 from '../images/bubble-icon-3.png'
import check from '../images/Check.png'
import icon1 from '../images/icon-pencil.png'
import icon2 from '../images/icon-arrow.png'
import icon3 from '../images/icon-doc.png'
import floorPlanBg from '../images/floor-plan-background2.png'
import icon4 from '../images/icon-fire.png'
import twitter from '../images/twt.svg'
import fb from '../images/fb.svg'
import ig from '../images/ig.svg'
import markerIcon from '../images/marker.png'
import phoneIcon from '../images/phone.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Navbar from 'react-bootstrap/Navbar'
import Mailchimp from './resource/mailchimp'
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import parse from 'html-react-parser';

// styles
const pageStyles = {
  color: "#232129",
}

const logoStyle = {
  maxWidth: '143px'
}

const redirectHome = () => {
  window.location.href = `${process.env.HOME_URL_PATH}`
}

const redirectLogin = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_in`
}

const redirectSignup = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/users/sign_up`
}

const redirectConsultation = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/consultation`
}

const redirectCs = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/consultation_reservations/new`
}

const redirectSubscribe = () => {
  window.location.href = `${process.env.GATSBY_URL_PATH}/subscription`
}

const redirectSocialMedia = (sc) => {
  window.location.href = parse(sc)[1].props.children.props.href
}

const formActionUrl = process.env.MAILCHIMP_FORM

const footerContent = graphql`
  query FOOTER {
    wpCategory(name: {eq: "Footer"}) {
      id
      posts {
        nodes {
          content
        }
      }
    }
  }
`

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles} id="root">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Lifeguide Consultancy</title>
        </Helmet>
      </div>
      <section className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <section className="section-pages first-section" id='head'>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Navbar collapseOnSelect expand="lg">
                      <Navbar.Brand href="#home" onClick={() => redirectHome()}>
                        <img src={Logo} style={logoStyle} className='w-100' alt="Logo" />
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                      <Navbar.Collapse id="responsive-navbar-nav">
                        <ul className="navbar-nav mx-auto">
                          <li className="nav-item">
                            <a className="nav-link" href="#head">Home</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#about-us">About Us</a>
                          </li>
                          <li className="nav-item">
                            <span className="nav-link" onClick={() => redirectSubscribe()}>Subscribe</span>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#how-it-works">How it works</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#faq">FAQ</a>
                          </li>
                        </ul>
                        <div className="form-inline my-2 my-lg-0">
                          <button className="btn btn-ghost mr-3" onClick={() => redirectLogin()}>Sign in</button>
                          <button className="btn btn-signup my-2 my-sm-0" onClick={() => redirectSignup()}>Sign up</button>
                        </div>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-5">
                    <h1>Reveal your fortune with ease.</h1>
                    <p>Do you wish to reveal your fortune and destiny? Take advantage of our Bazi and Feng Shui readings to make wise decisions.</p>
                    <button className="btn btn-join mt-4" type="submit" onClick={() => redirectSignup()}>Join us NOW</button>
                  </div>
                  <div className="col-12 col-md-12 col-lg-7">
                    <div className="mt-5">
                      <img src={background} className="d-block rounded feature_image" alt=""/>
                    </div>
                  </div>
                </div>
                <div className="row feature-highlight-box">
                  <div className="col-md-12 col-lg-4 mt-3">
                    <div className="card-deck">
                      <div className="card">
                        <img className="bubble-img" src={bubbleLogo1} alt="Card image cap" />
                        <div className="card-body p-0">
                          <h5 className="card-title">Life Reading</h5>
                          <p className="card-text">You can learn about your path through Life Reading. A clear understanding of your path helps you plan and live your life to the fullest.</p>
                          <span className="line-card orange"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 mt-3">
                    <div className="card-deck">
                      <div className="card">
                        <img className="bubble-img" src={bubbleLogo2} alt="Card image cap" />
                        <div className="card-body p-0">
                          <h5 className="card-title">Date Selection</h5>
                          <p className="card-text">DDate selection assists you in selecting a suitable date before undertaking activities such as getting married, undergoing a caesarean section, or moving house. Job, etc.</p>
                          <span className="line-card blue"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 mt-3">
                    <div className="card-deck">
                      <div className="card">
                        <img className="bubble-img" src={bubbleLogo3} alt="Card image cap" />
                        <div className="card-body p-0">
                          <h5 className="card-title">Property Audit</h5>
                          <p className="card-text">Property Audit guides people in deciding the most suitable location for various purposes, such as selecting residential and commercial properties for burial grounds.</p>
                          <span className="line-card green"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-pages second-section">
              <div className="container">
                <div className="row">
                  <div className="col-12" id="about-us">
                    <span className="d-block text-center text-gray mt-4 mb-3">Why you should trust us?</span>
                    <h2 className="text-center">Get know about us</h2>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <span className="flex-container">
                          <img src={check} alt="check" />
                          <p>Many types of consultation</p>
                        </span>
                        <p className="check-text">As part of our services, we provide various consultations, such as Life Readings, Date Selections, and Property Audits.</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <span className="flex-container">
                          <img src={check} alt="check" />
                          <p>Easy to use</p>
                        </span>
                        <p className="check-text">Our service is designed by experts in traditional Chinese beliefs. Get detailed results to help you lead a better life.</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <span className="flex-container">
                          <img src={check} alt="check" />
                          <p>Quite detailed results</p>
                        </span>
                        <p className="check-text">Our system is designed to be user-friendly. Simply input your data and read the results afterward.</p>
                      </div>
                      <div className="col-12 col-md-6">
                        <span className="flex-container">
                          <img src={check} alt="check" />
                          <p>Private &amp; Secure</p>
                        </span>
                        <p className="check-text">You can be assured that your information will be kept secure and private. No need to worry about data breaches when you access our service.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-pages third-section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center">Features we offer</h2>
                    <Tabs
                      defaultActiveKey="home"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="BaZi Calculator">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <h3>Calculate your BaZi chart</h3>
                            <p className="text-gray">Based on your birth year, month, day, and hour, the BaZi calculator will determine your four Pillars of Destiny.</p>
                            <button className="btn btn-join my-2 my-sm-0" type="submit" onClick={() => redirectSignup()}>Get Started Now</button>
                          </div>
                          <div className="col-12 col-md-6">
                            <img src={background} className="d-block feature_image" alt=""/>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="profile" title="Floor Plan">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <h3>Calculate your Floor Plan</h3>
                            <p className="text-gray">A Feng Shui floor plan will help you determine the best layout for your space to attract positive energy.</p>
                            <button className="btn btn-join my-2 my-sm-0" type="submit" onClick={() => redirectSignup()}>Get Started Now</button>
                          </div>
                          <div className="col-12 col-md-6">
                            <img src={floorPlanBg} className="d-block feature_image" alt=""/>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </section>
            <section className="fourth-section" id="how-it-works">
              <div className="container">
                <div className="row">
                  <div className="col-12 custom-margin-bottom">
                    <h2>How Lifeguide Works</h2>
                    <div className="stepper-wrapper">
                      <div className="stepper-item completed">
                        <div className="step-counter">
                          <img src={icon1} alt="icon pencil" />
                        </div>
                        <div className="step-name">
                        <h5>Sign Up</h5>
                        <p className="text-gray">Create an account to start using our features.</p>
                        </div>
                      </div>
                      <div className="stepper-item completed">
                        <div className="step-counter">
                          <img src={icon2} alt="icon cursor" />
                        </div>
                        <div className="step-name">
                        <h5>Choose Feature</h5>
                        <p className="text-gray">Select the one that you need.</p>
                        </div>
                      </div>
                      <div className="stepper-item active">
                        <div className="step-counter">
                          <img src={icon3} alt="icon document" />
                        </div>
                        <div className="step-name">
                        <h5>Input Data</h5>
                        <p className="text-gray">Input your data to help us find the best results for you.</p>
                        </div>
                      </div>
                      <div className="stepper-item">
                        <div className="step-counter">
                          <img src={icon4} alt="icon fire" />
                        </div>
                        <div className="step-name">
                        <h5>Result</h5>
                        <p className="text-gray">Calculations are completed and ready for you to read.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="sixth-section" id="faq">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <h2>FAQ</h2>
                    <p>Can’t find the answer you’re looking for? Reach out to our <span className="link" onClick={() => redirectCs()}>customer support</span> team.</p>
                  </div>
                  <div className="col-12 col-md-8 custom-margin-bottom">
                    <ul>
                      <li>
                        <p className="text-bold">How to subscribe?</p>
                        <p className="text-gray">Simply click the "Join us NOW" button to begin subscribing. You'll get access to a premium plan as soon as you subscribe. Through the premium plan, you may get a more comprehensive BaZi and Feng Shui reading.</p>
                      </li>
                      <li>
                        <p className="text-bold">Can I book a consultation?</p>
                        <p className="text-gray">Yes, you can schedule a consultation through our website. Although our service focuses on automatically reading your fate and fortune, we are also willing to serve those of you who prefer a more personalised and interactive reading.</p>
                      </li>
                      <li>
                        <p className="text-bold">Can I fully trust the results?</p>
                        <p className="text-gray">Yes, you can trust the calculations on our website. However, as the accuracy of the calculations is dependent on the algorithm, the results may be ambiguous. Therefore, if you have any doubts about the results, you can arrange for a consultation to clarify them.</p>
                      </li>
                      <li>
                        <p className="text-bold">What is the information that I need to put to receive the readings?</p>
                        <p className="text-gray">Our calculation system requires you to enter your name, gender, date and month of birth, and the date you would like to know the fate of. Don’t worry about data breaches as we will secure every information you input.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <footer className="page-footer pt-0">
              <div className="consultation-box">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <h2>Start Your Consultation With Professional</h2>
                        </div>
                        <div className="col-12 col-md-4 text-right">
                          <button className="btn btn-join mt-4" type="submit" onClick={() => redirectConsultation()}>Let's discuss</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-footer-dark">
                <StaticQuery
                  query={footerContent}
                  render={(data) => {
                    if (data.wpCategory.posts.nodes) {
                      const footer = data.wpCategory.posts
                      return (
                        <div className="container">
                          <div className="row p-0">
                            <div className="col-12 col-lg-4 mb-4">
                              <h6 className="text-uppercase font-weight-bold mb-3">
                                <img src={FooterLogo} style={logoStyle} className='w-100' />
                              </h6>
                              <div>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={phoneIcon} alt="phone" />
                                  </div>
                                  <div className="col-10 text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[3].content }} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <img src={markerIcon} alt="marker" />
                                  </div>
                                  <div className="col-10 text-gray">
                                    <div dangerouslySetInnerHTML={{ __html: footer.nodes[4].content }} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-lg-2 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Company</h6>
                              <p>
                                <a href="#head">Home</a>
                              </p>
                              <p>
                                <a href="#about-us">About Us</a>
                              </p>
                              <p>
                                <a href="#" onClick={() => redirectSubscribe()}>Subscribe</a>
                              </p>
                              <p>
                                <Link to="/resource">Resources</Link>
                              </p>
                              <p>
                                <Link to="/privacyPolicy">Privacy Policy</Link>
                              </p>
                            </div>
                            <div className="col-6 col-lg-2 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Help</h6>
                              <p>
                                <a href="#how-it-works">How it works</a>
                              </p>
                              <p>
                                <a href="#faq">FAQ</a>
                              </p>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                              <h6 className="text-uppercase font-weight-bold">Subscribe to our newsletter</h6>
                              <p className="text-white">The latest news, articles, and resources, sent to your inbox weekly.</p>
                              <Mailchimp
                                action={formActionUrl}
                                fields={[
                                  {
                                    name: 'EMAIL',
                                    placeholder: 'Email Address',
                                    type: 'email',
                                    required: true
                                  }
                                ]}
                              />
                            </div>
                          </div>
                          <hr className="bg-gray" />
                          <div className="row pt-2 pb-4">
                            <div className="col-6">
                              <p className="text-gray">&copy; 2021 Lifeguideconsultancy. All rights reserved.</p>
                            </div>
                            <div className="col-6 text-right footer-sns">
                              <img src={fb} onClick={() => redirectSocialMedia(footer.nodes[2].content)}/>
                              <img src={ig} onClick={() => redirectSocialMedia(footer.nodes[1].content)}/>
                              <img src={twitter} onClick={() => redirectSocialMedia(footer.nodes[0].content)}/>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  }}
                />
              </div>
            </footer>
          </div>
        </div>
      </section>
    </main>
  )
}

export default IndexPage
